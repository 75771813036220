import React, { useState, ReactChild, useEffect } from 'react';
import AddToHome from 'shared/AddToHome';
import { isMobileDevice, getLastVisit, saveLastVisit } from 'shared/utils';
import NoContent from 'components/NoContent';
import {
  MAIN_DOMAIN,
  STAGING_USER_SETTINGS_URL,
  TEST_SUBDOMAIN,
  USER_SETTINGS_URL,
} from 'shared/constants';
export interface UserWebPageType {
  userId?: string;
  userWebPageId?: string;
  webLogo: any;
  webLogoURL?: any;
  title1: string;
  title2: string;
  leftGradientFill: string;
  rightGradientFill: string;
  strongTextColor: string;
  actionButtonColor: string;
  companyName: string;
  subDomainName: string;
  createdAt?: number;
  savingsCard: PrescriptionCardType;
}

export interface PrescriptionCardType {
  backgroundColor: string;
  cardLogo: string;
  cardLogoURL: string;
  groupID: string;
  BIN: string;
  PCN: string;
  lightTextColor: string;
  memberID: string;
  savingCardTitle: string;
  secundaryColor: string;
  secundaryContentColor: string;
  secundaryTitleColor: string;
  strongTextColor: string;
}

export const initialWebValues: UserWebPageType = {
  webLogo: '',
  webLogoURL: null,
  title1: 'Compare prices',
  title2: 'and choose your savings',
  leftGradientFill: '#e5f1ff',
  rightGradientFill: '#FF6000',
  strongTextColor: '#1a3a61',
  actionButtonColor: '#FF6000',
  companyName: 'Elevated Health Network',
  subDomainName: '',
  savingsCard: {
    backgroundColor: '#FFFFFF',
    cardLogo: '',
    BIN: '',
    PCN: '',
    cardLogoURL: '',
    lightTextColor: '#a2afbe',
    savingCardTitle: 'Elevated Health Network',
    secundaryColor: '#e5f1ff',
    strongTextColor: '#19375c',
    groupID: '218438',
    memberID: '12345678910',
    secundaryContentColor: '#e5f1ff',
    secundaryTitleColor: '#19375c',
  },
};
const AppContext: any = React.createContext({});

type Props = {
  children: ReactChild;
};

const AppProvider = ({ children }: Props) => {
  const [searchedMedicationID, setSearchedMedicationID] = useState();
  const [searchedMedicationName, setSearchedMedicationName] = useState();
  const [searchedZipCode, setSearchedZipCode] = useState();
  const [showSearchDetails, setShowSearchDetails] = useState();
  const [medicationDetails, setMedicationDetails] = useState({});
  const [showAddToHome, setShowAddToHome] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [userWebPage, setUserWebPage] = useState<UserWebPageType>(initialWebValues);

  const addToHomeCanBeShowed =
    isMobileDevice.iOS() && !window.matchMedia('(display-mode: standalone)').matches;

  const showAddToHomeMessage = () => {
    setTimeout(() => {
      setShowAddToHome(true);
    }, 5000);
  };

  useEffect(() => {
    const getRemoteConfig = async () => {
      const subdomain = window.location.host.split('.')[0];
      const isLocal = subdomain.includes('localhost') || subdomain.includes('192');
      const isStaging = subdomain.includes('staging') || isLocal;
      const baseURL = isStaging ? STAGING_USER_SETTINGS_URL : USER_SETTINGS_URL;
      let requestURL = `${baseURL}/get-by/`;
      if (isLocal) {
        requestURL += TEST_SUBDOMAIN;
      } else {
        requestURL += subdomain;
      }
      const response = await fetch(requestURL);
      if (response.ok) {
        const { result } = await response.json();
        if (result) {
          window.document.title = result.companyName;
          
          console.log('WEBPAGE DATA', result);
          setUserWebPage(result);
          setConfigLoaded(true);
        } else {
          window.location.href = `https://www.${MAIN_DOMAIN}/`;
        }
      } else {
        console.log('HTTP-Error: ' + response.status);
        window.location.href = `https://www.${MAIN_DOMAIN}/`;
      }
    };
    getRemoteConfig();
  }, []);

  useEffect(() => {
    const checkLastVisit = () => {
      const lastVisitTimespan = getLastVisit();
      if (!lastVisitTimespan) {
        if (addToHomeCanBeShowed) {
          showAddToHomeMessage();
        }
      } else {
        const currentDate = new Date(Date.now());
        const lastVisitDate = new Date(lastVisitTimespan);
        // This allow us to show the Messages if the last visit was more than 2 days ago.
        const validDifference = currentDate.getDate() - lastVisitDate.getDate() >= 2;
        if (validDifference) {
          if (addToHomeCanBeShowed) {
            showAddToHomeMessage();
          }
        }
      }
      saveLastVisit(Date.now());
    };
    checkLastVisit();
  }, [addToHomeCanBeShowed]);

  return (
    <AppContext.Provider
      value={{
        userWebPage,
        searchedMedicationID,
        setSearchedMedicationID,
        searchedZipCode,
        setSearchedZipCode,
        showSearchDetails,
        setShowSearchDetails,
        medicationDetails,
        setMedicationDetails,
        searchedMedicationName,
        setSearchedMedicationName,
      }}
    >
      {configLoaded ? (
        <>
          <AddToHome
            logoURL={userWebPage.webLogoURL}
            onClose={() => setShowAddToHome(false)}
            show={showAddToHome}
          />
          {children}
        </>
      ) : (
        <NoContent message='Loading...' isLoading={true} />
      )}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
