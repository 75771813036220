import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import Home from 'containers/Home';
import SearchDetails from 'containers/SearchDetails';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/search-details' exact component={SearchDetails} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
