import React from 'react';
import { UserWebPageType } from 'containers/App/context';
import { defaultLogo } from 'shared/constants';

export const SavingCardTemplate = ({
  id = 'SavingCardTemplate',
  memberName = 'Name Surname',
  GRP = '218438',
  PCN = 'SC1',
  BIN = '610378',
  logoBase64 = defaultLogo,
  title = 'AmedicaRx',
  backgroundFillColor = '#fff',
  detailFillColor = '#e5f1ff',
  strongTextColor = '#1a3a61',
  lightTextColor = '#97a6b8',
  maxWidth = '380px',
  borderRadius = '14px',
}: {
  id?: string;
  memberName?: string;
  GRP?: string;
  PCN?: string;
  BIN?: string;
  logoBase64?: string;
  title?: string;
  backgroundFillColor?: string;
  detailFillColor?: string;
  strongTextColor?: string;
  lightTextColor?: string;
  maxWidth?: string;
  borderRadius?: string;
}) => {
  // const titleOffsetX = 274.3 + (23 - title.length) * 5;
  return (
    <svg
      className='saving-card-template'
      style={{
        maxWidth,
        boxShadow: '0px 20px 40px rgba(26, 58, 97, 0.06)',
        borderRadius,
      }}
      viewBox='0 0 462 280'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 298'>
        <g id='Group 272' filter='url(#filter0_d_267_169)'>
          <rect
            id='Rectangle 99'
            x='10.5'
            y='10.5'
            width='420.493'
            height='239'
            rx='19.5'
            fill='white'
            stroke='#FBFBFB'
          />
          <rect
            id='Rectangle 101'
            x='283.434'
            y='94.7761'
            width='113.433'
            height='69.2537'
            fill={detailFillColor}
          />
          <path
            id='Rectangle 100'
            d='M10 30C10 18.9543 18.9543 10 30 10L411.492 10C422.538 10 431.493 18.9543 431.493 30V69.7015L10 69.7015L10 30Z'
            fill={detailFillColor}
          />
          <g id='Member ID'>
            <text
              transform='translate(37.4629 101.94)'
              fill='black'
              xmlSpace='preserve'
              fontFamily='Work Sans'
              fontSize='10'
              fontWeight='600'
              letterSpacing='0em'
            >
              <tspan x='8.89648' y='12.3902'>
                ember ID
              </tspan>
            </text>
            <text
              transform='translate(37.4629 101.94)'
              fill='black'
              xmlSpace='preserve'
              fontFamily='Work Sans'
              fontSize='10'
              fontWeight='600'
              letterSpacing='0em'
            >
              <tspan x='0' y='12.3902'>
                M
              </tspan>
            </text>
          </g>
          <text
            id='Customer Care'
            transform='translate(35.4629 140.149)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='10'
            letterSpacing='0em'
          >
            <tspan x='0' y='12.3902'>
              Customer Care{' '}
            </tspan>
          </text>
          <text
            id='Please present this card at the pharmacy with a valid prescription.'
            transform='translate(35.4629 203.433)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='8'
            letterSpacing='0em'
          >
            <tspan x='0' y='9.91218'>
              Please Present This Card At The Pharmacy With A Valid Prescription.
              {/* Administered by Medical Security Card Company, LLC of Tucson, AZ. */}
            </tspan>
          </text>
          <text
            id='yourcompany.amedicarx.com'
            transform='translate(36.4629 216.567)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='8'
            letterSpacing='0em'
          >
            <tspan x='0' y='9.91218'>
              yourcompany.amedicarx.com
            </tspan>
          </text>
          <text
            id='discount only - not insurance'
            transform='translate(36.2695 183.134)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='10'
            fontWeight='600'
            letterSpacing='0em'
          >
            <tspan x='0' y='12.3902'>
              DISCOUNT ONLY - NOT INSURANCE
            </tspan>
          </text>
          <text
            id='25004415687'
            transform='translate(155.672 101.94)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='10'
            fontWeight='600'
            letterSpacing='0em'
          >
            <tspan x='6.32062' y='12.3902'>
              25004415687
            </tspan>
          </text>
          <g id='GRP 218438 BIN 006053 PCN MSC'>
            <text
              transform='translate(300.148 101.94)'
              fill='#1C5CFD'
              xmlSpace='preserve'
              fontFamily='Work Sans'
              fontSize='10'
              fontWeight='600'
              letterSpacing='0em'
            >
              <tspan x='19.9902' y='16.0917'>
                {' '}
                218438&#10;
              </tspan>
              <tspan x='20.2441' y='32.0917'>
                {' '}
                006053&#10;
              </tspan>
              <tspan x='20.3027' y='48.0917'>
                {' '}
                MSC
              </tspan>
            </text>
            <text
              transform='translate(300.148 101.94)'
              fill='#1C5CFD'
              xmlSpace='preserve'
              fontFamily='Work Sans'
              fontSize='10'
              letterSpacing='0em'
            >
              <tspan x='0' y='16.0917'>
                GRP
              </tspan>
              <tspan x='0' y='32.0917'>
                BIN{' '}
              </tspan>
              <tspan x='0' y='48.0917'>
                PCN
              </tspan>
            </text>
          </g>
          <text
            id='800-407-8156'
            transform='translate(154.479 140.149)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='10'
            fontWeight='600'
            letterSpacing='0em'
          >
            <tspan x='7.18655' y='12.3902'>
              800-407-8156{' '}
            </tspan>
          </text>
          <text
            id='Prescription Savings Card'
            transform='translate(296.566 39.8507)'
            fill='black'
            xmlSpace='preserve'
            fontFamily='Work Sans'
            fontSize='8'
            letterSpacing='0em'
          >
            <tspan x='0' y='9.91218'>
              Prescription Savings Card
            </tspan>
          </text>
        </g>
        <image
          width='255'
          height='88'
          transform='matrix(.44 0 0 .44 28.83 26.42)'
          xlinkHref={logoBase64}
          style={{ isolation: 'isolate' }}
        />
      </g>
      <defs>
        <filter
          id='filter0_d_267_169'
          x='0'
          y='0'
          width='461.492'
          height='280'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='10' dy='10' />
          <feGaussianBlur stdDeviation='10' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_267_169' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_267_169'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export const PillsIcon = ({ webPageStyles }: { webPageStyles?: UserWebPageType }) => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60 50C60 55.5228 55.5228 60 50 60L7.62939e-06 60L3.25826e-06 10C2.77543e-06 4.47717 4.47716 1.24834e-05 10 1.20005e-05L60 7.62939e-06L60 50Z'
        fill={webPageStyles?.actionButtonColor}
        opacity='0.1'
      />
      <path
        d='M22.6875 18.875C19.5391 18.875 17 21.4648 17 24.5625V35.9375C17 39.0859 19.5391 41.625 22.6875 41.625C25.7852 41.625 28.375 39.0859 28.375 35.9375V24.5625C28.375 21.4648 25.7852 18.875 22.6875 18.875ZM25.9375 30.25H19.4375V24.5625C19.4375 20.2969 25.9375 20.2969 25.9375 24.5625V30.25ZM43.8633 27.7617C40.6641 24.6133 35.5352 24.6133 32.3359 27.7617C29.1875 30.9609 29.1875 36.0898 32.3359 39.2891C35.5352 42.4375 40.6641 42.4375 43.8633 39.2891C47.0117 36.0898 47.0117 30.9609 43.8633 27.7617ZM33.3008 30.4531L41.1719 38.3242C36.043 41.5742 30.0508 35.582 33.3008 30.4531ZM42.8984 36.5977L35.0273 28.7266C40.1562 25.4766 46.1992 31.4688 42.8984 36.5977Z'
        fill={webPageStyles?.actionButtonColor}
      />
    </svg>
  );
};

export const PhoneIcon = ({ webPageStyles }: { webPageStyles?: UserWebPageType }) => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M60 50C60 55.5228 55.5228 60 50 60L7.62939e-06 60L3.25826e-06 10C2.77543e-06 4.47717 4.47716 1.24834e-05 10 1.20005e-05L60 7.62939e-06L60 50Z'
        fill={webPageStyles?.actionButtonColor}
        opacity='0.1'
      />
      <path
        d='M41.5781 34.0078L36.3984 31.8242C36.1445 31.7227 35.7383 31.6211 35.4844 31.6211C34.875 31.6211 34.0625 32.0273 33.707 32.4844L32.0312 34.5156C29.7969 33.2969 26.9531 30.4531 25.7344 28.2188L27.7656 26.543C28.2227 26.1875 28.6289 25.375 28.6289 24.7656C28.6289 24.5117 28.5273 24.1055 28.4258 23.8516L26.2422 18.6719C25.8867 17.9102 24.9219 17.25 24.1094 17.25C23.957 17.25 23.7031 17.3008 23.5508 17.3516L18.7773 18.418C17.7617 18.6719 17 19.6875 17 20.7031C17 33.1953 27.1055 43.3008 39.5469 43.25C40.5625 43.25 41.5781 42.4883 41.832 41.4727L42.8984 36.6992C42.9492 36.5469 43 36.293 43 36.1914C43 35.3281 42.3398 34.3633 41.5781 34.0078ZM39.4453 40.8633C28.4258 40.8125 19.4883 31.8242 19.4375 20.8047L24.0078 19.7383L26.1406 24.7148L22.6875 27.5586C25.0742 32.5859 27.6641 35.1758 32.6914 37.5625L35.5352 34.1094L40.5117 36.2422L39.4453 40.8633Z'
        fill={webPageStyles?.actionButtonColor}
      />
    </svg>
  );
};

export const SearchIcon = ({ webPageStyles }: { webPageStyles?: UserWebPageType }) => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 70C4.47715 70 -1.95703e-07 65.5228 -4.37114e-07 60L-3.0598e-06 0L60 -2.62268e-06C65.5228 -2.86409e-06 70 4.47715 70 10L70 70L10 70Z'
        fill={webPageStyles?.actionButtonColor}
        opacity='0.1'
      />
      <path
        d='M50.5732 48.4982L44.7727 42.6994C46.8872 40.1103 48.0415 36.8715 48.0415 33.5261C48.0512 25.5164 41.5329 19 33.5208 19C25.5183 19 19 25.5164 19 33.5164C19 41.5164 25.5183 48.0327 33.5208 48.0327C36.8672 48.0327 40.107 46.8788 42.6969 44.7648L48.4974 50.5636C48.7787 50.8448 49.157 51 49.5353 51C49.933 51 50.3113 50.8352 50.5732 50.5636C50.8448 50.2921 51 49.9236 51 49.5261C51 49.1382 50.8545 48.7697 50.5732 48.4982ZM45.1025 33.5164C45.1025 39.9067 39.9033 45.1042 33.5208 45.1042C27.1382 45.1042 21.9294 39.9067 21.9294 33.5164C21.9294 27.1358 27.1285 21.9382 33.5111 21.9382C39.8936 21.9382 45.1025 27.1358 45.1025 33.5164Z'
        fill={webPageStyles?.actionButtonColor}
      />
    </svg>
  );
};

export const CouponIcon = ({ webPageStyles }: { webPageStyles?: UserWebPageType }) => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M70 10C70 4.47715 65.5228 -3.91405e-07 60 -8.74228e-07L0 -6.11959e-06L-5.24537e-06 60C-5.72819e-06 65.5228 4.47714 70 9.99999 70L70 70L70 10Z'
        fill={webPageStyles?.actionButtonColor}
        opacity='0.1'
      />
      <path
        d='M53.5091 31.4413C54.2793 31.4413 54.901 30.8196 54.901 30.0494V23.8694C54.901 21.7352 53.1658 19.9999 51.0315 19.9999H18.8695C16.7352 19.9999 15 21.7352 15 23.8694V30.0587C15 30.4299 15.1485 30.7825 15.4083 31.0423C15.6681 31.3021 16.0207 31.4506 16.3919 31.4506C18.3498 31.4506 19.9459 33.0466 19.9459 35.0139C19.9459 36.9811 18.3498 38.5586 16.3919 38.5586C15.6217 38.5586 15 39.1803 15 39.9504V46.1397C15 48.274 16.7352 50.0092 18.8695 50.0092H51.0408C53.1751 50.0092 54.9103 48.274 54.9103 46.1397V39.9504C54.9103 39.1803 54.2886 38.5586 53.5184 38.5586C51.5605 38.5586 49.9644 36.9625 49.9644 35.0046C49.9644 33.0466 51.5419 31.4413 53.5091 31.4413ZM47.1713 35.0046C47.1713 37.9647 49.2406 40.5443 52.1172 41.1939V46.1397C52.1172 46.7336 51.6347 47.2254 51.0315 47.2254H18.8695C18.2663 47.2161 17.7838 46.7336 17.7838 46.1397V41.1939C20.6604 40.5443 22.7297 37.9647 22.7297 35.0046C22.7297 32.0445 20.6511 29.4648 17.7838 28.8153V23.8694C17.7838 23.2663 18.2663 22.7837 18.8695 22.7837H51.0408C51.6254 22.7837 52.1172 23.2663 52.1172 23.8694V28.8153C49.2406 29.4556 47.1713 32.0445 47.1713 35.0046Z'
        fill={webPageStyles?.actionButtonColor}
      />
      <path
        d='M38.3191 25.948C37.6695 25.7067 36.9365 26.0315 36.6859 26.6903L30.7843 42.4188C30.6636 42.7343 30.6729 43.0776 30.8214 43.3838C30.9606 43.69 31.2111 43.9313 31.5266 44.0426C31.6751 44.0983 31.8236 44.1262 31.972 44.1262C32.1483 44.1262 32.3246 44.089 32.4917 44.0148C32.7979 43.8756 33.0299 43.6251 33.1505 43.3096L39.0521 27.5812C39.3027 26.9316 38.9686 26.1985 38.3191 25.948Z'
        fill={webPageStyles?.actionButtonColor}
      />
      <path
        d='M33.1504 31.1165C33.1504 29.1864 31.5822 27.6182 29.6521 27.6182C27.722 27.6182 26.1538 29.1864 26.1538 31.1165C26.1538 33.0466 27.722 34.6148 29.6521 34.6148C31.5822 34.6148 33.1504 33.0466 33.1504 31.1165ZM30.6172 31.1165C30.6172 31.6454 30.1903 32.0816 29.6521 32.0816C29.1139 32.0816 28.6871 31.6547 28.6871 31.1165C28.6871 30.5783 29.1139 30.1515 29.6521 30.1515C30.1903 30.1515 30.6172 30.5876 30.6172 31.1165Z'
        fill={webPageStyles?.actionButtonColor}
      />
      <path
        d='M40.24 36.3872C38.3099 36.3872 36.751 37.9554 36.751 39.8855C36.751 41.8156 38.3192 43.3838 40.24 43.3838C42.1608 43.3838 43.7383 41.8156 43.7383 39.8855C43.7383 37.9554 42.1701 36.3872 40.24 36.3872ZM41.205 39.8762C41.205 40.4051 40.7782 40.8413 40.24 40.8413C39.7018 40.8413 39.2749 40.4144 39.2749 39.8762C39.2749 39.338 39.7018 38.9112 40.24 38.9112C40.7782 38.9112 41.205 39.3473 41.205 39.8762Z'
        fill={webPageStyles?.actionButtonColor}
      />
    </svg>
  );
};

export const ScriptsIcon = ({ webPageStyles }: { webPageStyles?: UserWebPageType }) => {
  return (
    <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 70C4.47715 70 -1.95703e-07 65.5228 -4.37114e-07 60L-3.0598e-06 0L60 -2.62268e-06C65.5228 -2.86409e-06 70 4.47715 70 10L70 70L10 70Z'
        fill={webPageStyles?.actionButtonColor}
        opacity='0.1'
      />
      <path
        d='M53.028 20H17.7664C16.243 20 15 21.243 15 22.7664V47.2336C15 48.757 16.243 50 17.7664 50H53.028C54.5514 50 55.7944 48.757 55.7944 47.2336V22.7664C55.7944 21.243 54.5514 20 53.028 20ZM47.785 22.8131H52.9813V28.0093C50.3645 27.4486 48.3365 25.4206 47.785 22.8131ZM52.9813 30.8224V39.1776C50.9533 39.4766 49.1121 40.4112 47.6542 41.8598C46.1963 43.3178 45.271 45.1589 44.972 47.1869H25.8224C25.5234 45.1589 24.5888 43.3178 23.1402 41.8598C21.6822 40.4019 19.8411 39.4766 17.8131 39.1776V30.8224C19.8411 30.5234 21.6822 29.5888 23.1402 28.1402C24.5981 26.6822 25.5234 24.8411 25.8224 22.8131H44.9626C45.271 24.8411 46.1963 26.6822 47.6542 28.1402C49.1121 29.5981 50.9439 30.5234 52.9813 30.8224ZM17.8131 28.0093V22.8131H23.0093C22.4486 25.4206 20.4206 27.4486 17.8131 28.0093ZM17.8131 41.9907C20.4206 42.5421 22.4579 44.5794 23.0093 47.1869H17.8131V41.9907ZM52.9813 41.9907V47.1869H47.785C48.3365 44.5794 50.3645 42.5421 52.9813 41.9907Z'
        fill={webPageStyles?.actionButtonColor}
      />
      <path
        d='M41.1309 30.1495C40.43 29.6542 39.4113 29.757 38.8599 30.3738L33.944 35.8878L32.0188 33.3178C31.5234 32.6449 30.5141 32.4673 29.7758 32.9159C29.402 33.1402 29.159 33.4766 29.0749 33.8692C29.0001 34.243 29.0842 34.6168 29.3178 34.9346L32.4674 39.1682C32.7571 39.5514 33.2337 39.7944 33.7477 39.8131H33.8132C34.3085 39.8131 34.7664 39.6168 35.0749 39.271L41.3739 32.2149C41.6449 31.9159 41.7664 31.542 41.7197 31.1589C41.6917 30.757 41.4767 30.4019 41.1309 30.1495Z'
        fill={webPageStyles?.actionButtonColor}
      />
    </svg>
  );
};
