import React, { useContext, useState } from 'react';
import Rodal from 'rodal';
import { HeaderContainer } from './styled';
import { Menu, Image, Button } from 'semantic-ui-react';
import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
import SearchBar from 'components/SearchBar';
import { AppContext } from 'containers/App/context';
import { isMobileDevice } from 'shared/utils';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import { MAIN_DOMAIN } from 'shared/constants';

const Header = () => {
  const { setShowSearchDetails, setMedicationDetails, userWebPage } = useContext(AppContext);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const shareUrl = `https://${userWebPage?.subDomainName}.${MAIN_DOMAIN}`;
  const title = userWebPage?.companyName;
  const iconSize = 40;

  const SMSEncoded = `${userWebPage?.companyName}%20-%20Save%20on%20meds!`;
  let SMSAction = `mms:?body=${SMSEncoded}`;

  if (isMobileDevice.iOS()) {
    SMSAction = `sms:&body=${SMSEncoded}`;
  }

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: userWebPage?.companyName,
          text: `${userWebPage?.title1}, ${userWebPage?.title2}`,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      setShowShareDialog(true);
    }
  };

  const onBackToHome = () => {
    setMedicationDetails({});
    setShowSearchDetails(false);
  };

  return (
    <HeaderContainer>
      <Menu borderless position='left'>
        <Menu.Item onClick={onBackToHome}>
          <Image src={userWebPage.webLogoURL || EHNIsoTrans} />
        </Menu.Item>
        {(isMobileDevice.android() || isMobileDevice.iOS()) && (
          <Menu.Menu position='right'>
            <Menu.Item>
              <Button basic color='blue' content='Share' icon='share' onClick={onShare} />
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <SearchBar />
      <Rodal visible={showShareDialog} onClose={() => setShowShareDialog(false)}>
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={iconSize} round />
        </TwitterShareButton>
        <Button
          className='sms-action'
          circular
          as='a'
          href={SMSAction}
          color='facebook'
          icon='chat'
        />
        <WhatsappShareButton url={shareUrl} title={title} separator=':: '>
          <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={iconSize} round />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl} subject={title} body='Great savings here!'>
          <EmailIcon size={iconSize} round />
        </EmailShareButton>
      </Rodal>
    </HeaderContainer>
  );
};
export default Header;
