import React, { useContext } from 'react';
import { FooterContainer } from './styled';
import { Menu, Header, Image, Grid } from 'semantic-ui-react';
import EHNIsoTrans from 'assets/logos/ehnisoTrans.png';
import { AppContext } from 'containers/App/context';
import { PhoneIcon } from 'shared/icons';
import NoteIcon from 'assets/icons/note.svg';

const Footer = () => {
  const { userWebPage } = useContext(AppContext);
  const currentDate = new Date();

  return (
    <FooterContainer strongTextColor={userWebPage.strongTextColor}>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Menu stackable borderless position='left'>
              <Menu.Item>
                <Grid columns={1} textAlign='left'>
                  <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                      <Header as='h3' image>
                        <Image className='logo' src={userWebPage.webLogoURL || EHNIsoTrans} />
                        <br />
                        With the {userWebPage.companyName} Prescription Savings Card you can save up
                        to 80% on prescription drugs! Once you have your card you can use it for
                        your entire family as often as you like!
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Item>

              <Menu.Menu stackable borderless position='right'>
                <Grid textAlign='left'>
                  <Grid.Row columns={2} verticalAlign='middle'>
                    {/* <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Header as='h3' image>
                        <Grid textAlign='left'>
                          <Grid.Row columns={3}>
                            <Grid.Column mobile={4} computer={16}>
                              <PillsIcon webPageStyles={userWebPage} />
                            </Grid.Column>
                            <Grid.Column mobile={8} computer={16}>
                              Pharmacy Help Desk <br />
                              <Header.Subheader>800-404-1031</Header.Subheader>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header>
                    </Grid.Column> */}

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <Header as='h3' image>
                        <Grid textAlign='left'>
                          <Grid.Row columns={3}>
                            <Grid.Column mobile={4} computer={16}>
                              <PhoneIcon webPageStyles={userWebPage} />
                            </Grid.Column>
                            <Grid.Column mobile={8} computer={16}>
                              Prescription Savings Card Customer Care <br />
                              <Header.Subheader>800-407-8156</Header.Subheader>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} verticalAlign='middle'>
          <Grid.Column>
            <Header as='h3' className='copyright'>
              <Grid textAlign='center'>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Header as='h3' image>
                      <Image src={NoteIcon} size='small' />
                      Important Notes
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} verticalAlign='middle'>
                  {userWebPage.savingsCard.apiDiff === 'saveScript' ? (
                    <Grid.Column className='disclaimer'>
                      <br />
                      Discounts are available exclusively through participating pharmacies. The
                      range of the discounts will vary depending on the type of prescription and the
                      pharmacy chosen. This program does not make payments directly to pharmacies.
                      Members are required to pay for all prescription purchases. Cannot be used in
                      conjunction with insurance. You may contact customer care anytime with
                      questions or concerns, to cancel your registration, or to obtain further
                      information. This program is administered by Medical Security Card Company,
                      LLC, Tucson, AZ.
                      <br />
                    </Grid.Column>
                  ) : (
                    <Grid.Column className='disclaimer'>
                      <br />
                      Prescription prices may vary from pharmacy to pharmacy and are subject to
                      change. The pricing estimates given are based on the most recent information
                      available and may change based on when you actually fill your prescription at
                      the pharmacy.
                      <br />
                      <br />
                      The day supply is based upon the average dispensing patterns or the specific
                      drug and strength. The Program, as well as the prices and the list of covered
                      drugs, can be modified at any time without notice.
                    </Grid.Column>
                  )}
                </Grid.Row>
                {/* <Grid.Row columns={1} verticalAlign='middle'>
                  <Grid.Column className='disclaimer'>
                    <b>DISCOUNT ONLY - NOT INSURANCE.</b>
                    <br /> Discounts are available exclusively through participating pharmacies. The
                    range of the discounts will vary depending on the type of prescription and the
                    pharmacy chosen. This program does not make payments directly to pharmacies.
                    Members are required to pay for all prescription purchases. Cannot be used in
                    conjunction with insurance. You may contact customer care anytime with questions
                    or concerns, to cancel your registration, or to obtain further information. This
                    program is administered by Medical Security Card Company, LLC, Tucson, AZ.
                  </Grid.Column>
                </Grid.Row> */}
                <Grid.Row columns={1} verticalAlign='middle'>
                  <Grid.Column className='copyright-p'>
                    © {currentDate.getFullYear()} AmedicaRx | All Rights Reserved
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FooterContainer>
  );
};
export default Footer;
