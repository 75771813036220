import styled from 'styled-components';
export interface PrescriptionCardType {
  backgroundColor: string;
  secundaryColor: string;
  strongTextColor: string;
  lightTextColor: string;
  secundaryTitleColor: string;
  secundaryContentColor: string;
}

interface TemplateType {
  isResponsive: boolean;
  savingsCardStyles: PrescriptionCardType;
}

export const TemplateContainer = styled.div<TemplateType>`
  // left: -1800px;
  // position: absolute;
  // z-index: -99;
  width: 530px;
  height: 365px;
  // margin: 20px auto;
  margin-left:12px;
  background: ${(props) => props.savingsCardStyles.backgroundColor};
  border: 1px solid rgb(128 128 128 / 22%);
  border-radius: 20px;

  .card-header{
    margin-top: 10px !important;
  }

  .topLeftImage {
    width: auto;
    height: 88px;
    margin-bottom: 2px;
  }

  .leftColumn {
    padding-left: 10px;
    white-space: nowrap;
  }

  .leftColumn h2.ui.header {
    margin-top: 9px !important;
  }
  .ui.grid {
    margin: 0 !important;
  }

  .ui.header {
    margin: 0px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important;
  }

  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }

  .noMarginPaddingBottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 40px !important;
  }

  .discount-box{
    padding-top: 0px !important;
  }

  .noPaddingTop {
    padding-top: 0 !important;
  }

  .text-center {
    text-align: center;
  }

  .bottomUrl {
    text-align: center;
    width: 100%;
    font-size: 24px !important;
    // margin-top: 4px !important;
  }

  .bottomUrl h4 {
    text-align: center;
    font-size: 20px !important;
    // margin-top: 4px !important;
  }

  .marginBox {
    padding-top: 200px;
    margin-left: 50px;
    background: ${(props) => props.savingsCardStyles.secundaryColor};
    height: min-content;
    max-width: 200px;
    border-radius: 5px;
  }

  .text-right {
    text-align: right;
  }

  h1.ui.header {
    color: ${(props) => props.savingsCardStyles.strongTextColor} !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  h3.ui.header {
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
    font-size: 17px !important;

    /* opacity: 0.5 !important; */
    text-align: left !important;
  }

  h2.ui.header {
    color: ${(props) => props.savingsCardStyles.strongTextColor} !important;
    font-size: 17px !important;
    text-align: left !important;
    font-weight: bold !important;
  }

  h4.ui.header {
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
    /* opacity: 0.5 !important; */
    font-size: 12px !important;
  }

  h3.ui.header.secundary-title {
    color: ${(props) => props.savingsCardStyles.secundaryTitleColor} !important;
  }

  h2.ui.header.secundary-content {
    color: ${(props) => props.savingsCardStyles.secundaryContentColor} !important;
  }

  img.ui.small.image {
    width: 150px !important;
    height: auto;
    max-width: 250px;
    margin-left: 25px;
    margin-top: 8px;
    /* top: 9px;
    left: 9px; */
    // border-top-left-radius: 7px;
    //   position: absolute !important;
    //   top: 93px !important;
    //   left: 100px !important;
  }

  .ui.grid.memberDetails {
    > .row > .column {
      padding: 0 25px !important;
    }
    // top: 180px !important;
    // position: absolute !important;
    // left: 127px !important;
    width: 100%;
    .ui.grid > .row {
      padding: 0 !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }
  .ui.grid.memberDetails {
    > .row > .column.grpcol .six.wide.column{
    padding-left: 10px !important;
  }

  .ui.grid.memberDetails .noMarginPaddingBottom .column.row {
    margin-bottom: 14px !important;
  }

  ${(props) =>
    props.isResponsive &&
    `
  @media (max-width: 767px) {
    width: 323px;
    height: 241px;
    margin: 0 auto;
    margin-bottom: 18px;
    img.ui.small.image {
      width: 91px !important;
    }
    .ui.grid.memberDetails {
      top: 75px !important;
    }

    h3.ui.header {
      font-size: 12px !important;
    }

    h2.ui.header {
      font-size: 12px !important;
    }
  } 

  `}
`;

export const TopLeftBG = styled.div<TemplateType>`
  width: auto;
  height: 70px;
  // left: 89px;
  // top: 84px;
  background: ${(props) => props.savingsCardStyles.secundaryColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.isResponsive &&
    `
    @media (max-width: 767px) {
      width: 151.97px;
      height: 51px;
    }
    `}
`;

export const CenterBG = styled.div<TemplateType>`
  position: absolute;
  width: 147px;
  height: 91px;
  right: 108px;
  top: 153px;
  background: ${(props) => props.savingsCardStyles.secundaryColor};
  ${(props) =>
    props.isResponsive &&
    `
    @media (max-width: 767px) {
      width: 147px;
      height: 90px;
      right: 10px;
      top: 48px;
    }
    `}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  text-align: right;
  padding: 20px 30px;
`;
