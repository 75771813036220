import styled from 'styled-components';
export interface PrescriptionCardType {
  backgroundColor: string;
  secundaryColor: string;
  strongTextColor: string;
  lightTextColor: string;
  secundaryTitleColor: string;
  secundaryContentColor: string;
}

interface TemplateType {
  isResponsive: boolean;
  savingsCardStyles: PrescriptionCardType;
}

export const TemplateContainer = styled.div<TemplateType>`
  width: 389px;
  height: 264px;
  margin: 20px auto;
  background: ${(props) => props.savingsCardStyles.backgroundColor};
  border: 1px solid rgb(128 128 128 / 22%);
  border-radius: 20px;

  .ui.grid {
    margin: 0 !important;
  }
  .contact {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fillLine {
    margin-top: 15px;
    border-color: ${(props) => props.savingsCardStyles.secundaryColor};
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    height: 0px;
  }

  .disclaimer {
    margin-top: 5px;
    font-size: 11px;
    font-weight: semibold;
    width: 100%;
    margin-x: auto;
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
  }

  .smallPaddingTop {
    padding-top: 5px !important;
  }

  .defaultText {
    padding: 0 20px;
    margin-y: auto;
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
    /* opacity: 0.5 !important; */
    font-size: 10px !important;
  }

  .ui.header {
    margin: 0px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 15px !important;
  }

  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }

  .noMarginPaddingBottom {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .noPaddingTop {
    padding-top: 0 !important;
  }

  .text-center {
    text-align: center;
  }

  .bottomUrl {
    text-align: center;
    width: 100%;
  }

  .marginBox {
    padding-top: 200px;
    // margin-left: 20px;
    background: ${(props) => props.savingsCardStyles.secundaryColor};
    height: min-content;
    max-width: 155px;
    border-radius: 5px;
  }

  .text-right {
    text-align: right;
  }

  h1.ui.header {
    color: ${(props) => props.savingsCardStyles.strongTextColor} !important;
    font-size: 12px !important;
    font-weight: bold !important;
  }

  h3.ui.header {
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
    font-size: 12px !important;
    /* opacity: 0.5 !important; */
    text-align: left !important;
  }

  h2.ui.header {
    color: ${(props) => props.savingsCardStyles.strongTextColor} !important;
    font-size: 14px !important;
    text-align: left !important;
    font-weight: bold !important;
  }

  h4.ui.header {
    color: ${(props) => props.savingsCardStyles.lightTextColor} !important;
    /* opacity: 0.5 !important; */
    font-size: 10px !important;
  }

  h3.ui.header.secundary-title {
    color: ${(props) => props.savingsCardStyles.secundaryTitleColor} !important;
  }

  h2.ui.header.secundary-content {
    color: ${(props) => props.savingsCardStyles.secundaryContentColor} !important;
  }

  img.ui.small.image {
    // width: 142px !important;
    height: 49px;
    top: 9px;
    left: 9px;
    // border-top-left-radius: 20px;
    //   position: absolute !important;
    //   top: 93px !important;
    //   left: 100px !important;
  }
  .sc-logo-front{
    height: 49px !important;
    object-fit: contain !important;
    max-height: 55px !important;
    // left: -20px !important;
    width: auto !important;
  }
  .ui.grid.memberDetails {
    > .row > .column {
      padding: 0 5px !important;
    }
    // top: 180px !important;
    // position: absolute !important;
    // left: 127px !important;
    width: 389px;
    .ui.grid > .row {
      padding: 0 !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }

  ${(props) =>
    props.isResponsive &&
    `
  @media (max-width: 767px) {
    width: 323px;
    height: 241px;
    margin: 0 auto;
    margin-bottom: 18px;
    img.ui.small.image {
      width: 91px !important;
    }
    .ui.grid.memberDetails {
      top: 75px !important;
    }

    h3.ui.header {
      font-size: 11px !important;
    }

    h2.ui.header {
      font-size: 12px !important;
    }
  }
  `}
`;

export const TopLeftBG = styled.div<TemplateType>`
  width: 389px;
  height: 58px;
  // left: 89px;
  // top: 84px;
  background: ${(props) => props.savingsCardStyles.secundaryColor};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${(props) =>
    props.isResponsive &&
    `
    @media (max-width: 767px) {
      width: 151.97px;
      height: 51px;
    }
    `}
`;

export const CenterBG = styled.div<TemplateType>`
  position: absolute;
  width: 147px;
  height: 91px;
  right: 108px;
  top: 153px;
  background: ${(props) => props.savingsCardStyles.secundaryColor};
  ${(props) =>
    props.isResponsive &&
    `
    @media (max-width: 767px) {
      width: 147px;
      height: 90px;
      right: 10px;
      top: 48px;
    }
    `}
`;

export const HeaderContainer = styled.div`
  width: 100%;
  text-align: right;
  padding: 20px 30px;
  position: relative;
  top: -54px;
`;
