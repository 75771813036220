import styled from 'styled-components';

export const HomeContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 600px;
  @media (max-width: 767px) {
    padding-top: 420px;
  }
`;

export const LeftGradientReq = styled.div<{ fill: string }>`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 80%;
  height: 654px;
  left: 0px;
  top: 0px;

  @media (max-width: 767px) {
    width: 100%;
    height: 580px;
  }

  background: linear-gradient(
      123.6deg,
      rgba(255, 255, 255, 1.0),
      rgba(255, 255, 255, 0.8)
    ),
    ${(props) => `linear-gradient(0deg, ${props.fill}, ${props.fill}), ${props.fill}`};
    /* linear-gradient(0deg, #e6f1ff, #e6f1ff), ${(props) => props.fill}; */
`;

export const RightGradientRq = styled.div<{ fill: string }>`
  border-bottom-right-radius: 150px;
  position: absolute;
  width: 650px;
  height: 650px;
  right: -50px;
  top: 280px;

  background: linear-gradient(123.6deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9)),
    ${(props) => `linear-gradient(0deg, ${props.fill}, ${props.fill}), ${props.fill}`};

  transform: rotate(-180deg);

  @media (max-width: 767px) {
    width: 200px;
    height: 332px;
    right: 0;
  }
`;
