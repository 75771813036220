import styled from 'styled-components';
// import { colors } from 'shared/theme';

export const SavingCardTemplateContainer = styled.div`
  width: 400px;
  height: 550;
  margin: 10px auto;
  background: transparent;
`;

export const HowItWorksContainer = styled.div<{ strongTextColor: string }>`
  position: relative;
  width: 100%;
  h2.ui.header {
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: -0.01em;
    color: ${(props) => props.strongTextColor};
  }
  h3.ui.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${(props) => props.strongTextColor};
    margin-bottom: 15px;
  }
  h3.ui.header .sub.header {
    font-family: Ubuntu;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
    max-width: 300px;
    color: ${(props) => props.strongTextColor};
  }
  .ui.grid {
    /* margin-bottom: 180px; */
  }
  .ui.header > .image:not(.icon),
  .ui.header > img {
    margin-bottom: 30px;
    width: 64px;
  }

  img.ui.image.partners-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    img.ui.image.partners {
      display: none;
    }
    img.ui.image.partners-mobile {
      display: block;
      width: 100%;
      margin-top: 50px;
    }
  }
`;
