import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const NoContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 600px;
  position: relative;
  flex-direction: column;
  color: gray;
  margin: 0 auto;
  h1,
  i.icon {
    color: #ced0d6;
  }
`;
const NoContent = ({ message, isLoading }: { message: string; isLoading?: boolean }) => {
  return (
    <NoContentContainer>
      <Icon loading={isLoading} name={isLoading ? 'spinner' : 'inbox'} size='massive' />
      <h1>{isLoading ? 'Loading...' : message}</h1>
    </NoContentContainer>
  );
};

export default NoContent;
