import React from 'react';
import styled from 'styled-components';
import closeIcon from '../assets/icons/close.png';
import logoSoloIcon from '../assets/logos/ehnLogoTransSmall.png';
import { Image } from 'semantic-ui-react';
import { colors } from './theme';

type AddToHomeTypes = {
  show: boolean;
  logoURL: string;
  onClose: () => void;
};

const AddToHomeContainer = styled.div<{ show: boolean }>`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px #d4d4d5, 0 0 0 1px #f4f4f7;
  position: absolute;
  width: 343px;
  margin: 0 auto;
  right: 0;
  left: 0;
  height: 83px;
  padding: 15px;
  display: flex;
  z-index: 10000;
  ${(props) =>
    props.show
      ? `
        top: 20px;
    `
      : `
        top: -100px;
    `};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all;
  .close-icon {
    height: 12px;
    cursor: pointer;
    top: -25px;
    right: -3px;
  }
  .logo-icon {
    width: 49px;
    margin-right: 16px;
  }
  p {
    margin: 0;
    color: ${colors.basicText};
    font-family: Ubuntu;
    font-weight: lighter;
    font-size: 16px;
    .addToHomeShare {
      display: inline-block;
      height: 15px;
      background-repeat: no-repeat;
      width: 11px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAA8CAYAAAAQTCjdAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAASCQAAEgkB80sG3AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKjSURBVGiB7dpPiFVVHMDxz7m9NCtMyI2bEJEC25WrEkzHUXCRraIwdNE8ZxfYIlcis1ZEbGEzT1QCF4XgH1CyWhUoJKIWNRGEiCNKmkSiYTzfcfGuOokx77x3ZlS8382959zfn+/invvn3RdijHIRBrwkOINCYXEcdjpb7VyiYdBsLScwt5y6IloYdzqXo36Ro0gY9IKWo+5JwmzBt2HQnBw9ehYNazyn5TBee8Dh+Vq+CWu92GufnkTDR6ab7gDeBNHouMM/l9tXTXM0fGBmL726Fg1Dam74EsvKqR8VNowL2Yzj5f7rnnE4DHq2235diYYhhTGfC94up35T0y+6di/ITU0rKVd+sEi0P7xr2pSJOu8zvF+OzqEv7vDH/WFxt7/cshy/ticsN8sXYUht0kXDOlsF9XJ4UaEvNoz9X3zc5bKaZThbyr5jzJ4wlNY7KTgM+ES0vhz+KeiPw36fKC/ucAF9uFBOrTZm26SJCtaV27+xIo7cXdkTEhvOoh+XyxprU1qnim7CQdGK2HAyKRexYVShT3RItDElN+mkjiP2Ym+S3f01hv2EVal5WW6hU0ElmptaqJuBpXg6MbeFH2LDpU6CQ93zWIKnEvs0cayGU3glMfkOo1jQYewZzOuyz7FC95Jwo5OgUFfgeg993hh/eTqCTxOSm/iuk8DY0Ap1b2GhtHXxsfZN4j/X0fOx4auEIknEhqv4OiUn1L13Z/+xWfWVaG4q0dxUormpRHNTieamEs1NJZqbJ1Q0jHvdiJo5S2cVjSNOCHZhn3/SnuYnIvl3yomIIz7MXZMn9hydRCrR3FSiualEc1OJ5qYSzU0lmptKNDePjWgwcPePT7/g+4cp8wCW4GXaryK3tL+mLdD5x62ppllgu7bso8q/2HIbzGWdNmWnSJwAAAAASUVORK5CYII=);
      background-size: 11px 15px;
      text-indent: -9999em;
      overflow: hidden;
      margin-left: 4px;
    }
    span {
      font-weight: bold;
    }
  }
`;

const AddToHome = ({ show, onClose, logoURL }: AddToHomeTypes) => {
  return (
    <AddToHomeContainer show={show}>
      <Image className='logo-icon' src={logoURL || logoSoloIcon} alt='EHN logo' />
      <p>
        Install this web app on your iPhone: tap
        <span className='addToHomeShare' /> and then <span>Add to Home Screen</span>
      </p>
      <Image className='close-icon' onClick={onClose} src={closeIcon} alt='Close Icon' />
    </AddToHomeContainer>
  );
};

export default AddToHome;
