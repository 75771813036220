import React, { useContext } from 'react';
import { HowItWorksContainer, SavingCardTemplateContainer } from './styled';
import { Grid, Header, Image, Responsive } from 'semantic-ui-react';
import Partners from 'assets/bgs/mapBg.svg';
import PartnersM from 'assets/bgs/mapBgM.svg';
import { SearchIcon, CouponIcon, ScriptsIcon } from 'shared/icons';
import { AppContext } from 'containers/App/context';
import DiscountCardFrontTemplate from 'components/DiscountCardFrontTemplate';
import DiscountCardBackTemplate from 'components/DiscountCardBackTemplate';

const HowItWorks = () => {
  const { userWebPage } = useContext<any>(AppContext);
  return (
    <HowItWorksContainer strongTextColor={userWebPage.strongTextColor}>
      <Responsive {...Responsive.onlyMobile}>
        {
          <SavingCardTemplateContainer>
            <DiscountCardFrontTemplate
              logoBase64={userWebPage.savingsCard.cardLogoURL || undefined}
              title={userWebPage.savingsCard.savingCardTitle}
              backgroundFillColor={userWebPage.savingsCard.backgroundColor}
              detailFillColor={userWebPage.savingsCard.secundaryColor}
              lightTextColor={userWebPage.savingsCard.lightTextColor}
              strongTextColor={userWebPage.savingsCard.strongTextColor}
              GRP={userWebPage.savingsCard.groupID}
              PCN={'MSC'}
              subdomain={userWebPage.subDomainName}
              userWebPage={userWebPage}
            />
            <DiscountCardBackTemplate
              logoBase64={userWebPage.savingsCard.cardLogoURL || undefined}
              title={userWebPage.savingsCard.savingCardTitle}
              backgroundFillColor={userWebPage.savingsCard.backgroundColor}
              detailFillColor={userWebPage.savingsCard.secundaryColor}
              lightTextColor={userWebPage.savingsCard.lightTextColor}
              strongTextColor={userWebPage.savingsCard.strongTextColor}
              GRP={userWebPage.savingsCard.groupID}
              PCN={'MSC'}
              subdomain={userWebPage.subDomainName}
            ></DiscountCardBackTemplate>
          </SavingCardTemplateContainer>
        }
      </Responsive>
      <Header as='h2'>How it works</Header>
      <Grid doubling textAlign='center' centered>
        <Grid.Row columns={3} centered verticalAlign='middle'>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <SearchIcon webPageStyles={userWebPage} />
              <br />
              Start with a search
              <Header.Subheader>
                Look up prescriptions to find the lowest prices in your area at participating
                pharmacies
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <CouponIcon webPageStyles={userWebPage} />
              <br />
              Get your prescription savings card
              <Header.Subheader>
                Download your Prescription Savings Card. No registration and 100% free!
              </Header.Subheader>
            </Header>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as='h3' image>
              <ScriptsIcon webPageStyles={userWebPage} />
              <br />
              Save on your prescriptions
              <Header.Subheader>
                Simply show your card at any of the over 60,000 participating pharmacies nationwide
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} style={{ justifyContent: 'center' }}>
          <Image className='partners' src={Partners} />
          <Image className='partners-mobile' src={PartnersM} />
        </Grid.Row>
      </Grid>
    </HowItWorksContainer>
  );
};

export default HowItWorks;
