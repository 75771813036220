import React from 'react';
import { TemplateContainer, TopLeftBG, HeaderContainer } from './styled';
import { Image, Header, Grid } from 'semantic-ui-react';
import { defaultLogo } from 'shared/constants';

const DiscountCardFrontTemplate = ({
  id = 'SavingCardTemplateFront1',
  memberName = 'MSC42286540',
  GRP = '218438',
  PCN = 'SC1',
  BIN = '610378',
  logoBase64 = defaultLogo,
  title = 'AmedicaRx',
  backgroundFillColor = '#fff',
  detailFillColor = '#e5f1ff',
  strongTextColor = '#1a3a61',
  lightTextColor = '#97a6b8',
  isResponsive = false,
  subdomain = 'www',
  secundaryTitleColor = '#97a6b8',
  secundaryContentColor = '#97a6b8',
  userWebPage = {},
}: {
  id?: string;
  memberName?: string;
  GRP?: string;
  PCN?: string;
  BIN?: string;
  logoBase64?: any;
  title?: string;
  backgroundFillColor?: string;
  detailFillColor?: string;
  strongTextColor?: string;
  lightTextColor?: string;
  isResponsive?: boolean;
  subdomain?: string;
  secundaryTitleColor?: string;
  secundaryContentColor?: string;
  userWebPage?: any;
}) => {
  const savingsCardStyles = {
    backgroundColor: backgroundFillColor,
    secundaryColor: detailFillColor,
    strongTextColor: strongTextColor,
    lightTextColor: lightTextColor,
    secundaryTitleColor: secundaryTitleColor,
    secundaryContentColor: secundaryContentColor,
  };

  const { savingsCard } = userWebPage;
  return (
    <TemplateContainer id={id} savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
      <TopLeftBG savingsCardStyles={savingsCardStyles} isResponsive={isResponsive}>
        {/* <Image className='topLeftImage sc-logo-front' id='sc-logo-front' src={"https://ehn-provider-api-staging-attachmentsbucket-kmh2tgkbebe3.s3.amazonaws.com/public/1682574817379-images%20%281%29...1%29.png?AWSAccessKeyId=ASIAVYDVDMWMOVTXKPOZ&Expires=1682578187&Signature=7fvptBIEe0lGDRSoikw9Qxeh8fs%3D&X-Amzn-Trace-Id=Root%3D1-644a1786-14a4ce3d2062b80f36ddefe7%3BParent%3D23edf55f6f4ef49a%3BSampled%3D0%3BLineage%3D0a430081%3A0&x-amz-security-token=IQoJb3JpZ2luX2VjEDcaCXVzLWVhc3QtMSJHMEUCIQC8zhLWqnfz%2BCYJRiQRySa8HSXcqu0bvIVx0KsEMKYQxwIgLyCXK3OBViNqJWj6HV8c1oBgTE%2Bpn%2BQVD5nVnx13WAQqowMIQBABGgwzOTUzODI1ODA2MzIiDAjwoH4hdYF3d%2FdUYiqAA3%2B3Ic7l8kkx72LH4%2BMMfq4Tr4%2BH27k3Cmd2n%2FguqHLkBBilUJdbkDwuOIWtWqtOsuk5bQVnuUvvH1PncGAYf7APjWi%2BEaKcOzsf0mBtfk2wbxiEkIkzPP9tlG95zwmPwMdecsTe9yng45gTeMLLG%2FZgwqKVzGavq05E07RiO062Khqh3I2S87oeTIhSuprDZRNgBXGd2w%2FApQhzC40zy42JerL%2BcyS5d4N56exr6qs9W%2FbwBnjDhoyktbpN0q%2BD7sq0vUSzYORFkraXx0aVXbg0gzxzgOkUfb%2F5QSPsm4pvXJUGpurpdH7VEQ39fASoTNDV4LU5QmJd%2B7wr%2B%2BAYuEcHb%2FBgD1MsnDPVHEwS8AT52c%2BdMbcRWgYbpidROJWqfpBzMKdPEvmn4ZAYBb9L7m8dB%2FPkCIl4RIPLXFUFMQzmjxOiu3sbBry9IcyrJqHLKFpnQq2nSeWE9jHFiJyijbd9XSWqi%2B0HU5yJAOm5WJJgVSux4SBgGLAQEabunWkSHTDIraiiBjqdAQ8LV%2BZceAU8bNmCoOHgfA8dR0bCp%2BLDQzOeqVaAbbJ6YienGz6WMncZTC%2FmZFHZrq4dQg7RsS87S2SQsNY7C7RHw6I51so0ZXE4K1oRo%2BR6SRTL7MdQmmlYSc1cVX6a2BkcQ2wk3n5kiPXCwlMGpcstJZSCyqMmGBCR5Iy8nCDc2z5cukOVTGyCbzlJI2f%2BRTtjnuCdMP9HrOXl8UM%3D"} size={'small'} /> */}
        <Image
          className='topLeftImage sc-logo-front'
          id='sc-logo-front'
          src={logoBase64}
          size={'small'}
        />
        <HeaderContainer>
          <Header as='h1'>{title}</Header>
          <Header as='h4'>prescription savings card</Header>
        </HeaderContainer>
      </TopLeftBG>
      <Grid className='memberDetails'>
        <Grid.Row columns={2} className={'noMarginPaddingBottom'}>
          <Grid.Column width={9} className='leftColumn' textAlign='left'>
            <Grid>
              <Grid.Row columns={1}>
                <Grid.Column width={8}>
                  <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                    Member ID
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Header as='h2'>{savingsCard?.memberID || '12345678910'}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={8}>
                  <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                    Customer Care #
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Header as='h2'>{savingsCard?.customerCare || '(833) 901-4747'}</Header>
                </Grid.Column>
              </Grid.Row>
              {savingsCard?.apiDiff === 'saveScript' ? (
                <Grid.Row columns={2}>
                  <Grid.Column width={8}>
                    <Header as='h1' style={{ opacity: 0.5, fontWeight: 'normal' }}>
                      Pharmacy Help #
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={9}>
                    <Header as='h2'>800-404-1031</Header>
                  </Grid.Column>
                </Grid.Row>
              ) : null}
            </Grid>
          </Grid.Column>
          <Grid.Column width={5} className='marginBox grpcol'>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    GRP
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.groupID || GRP}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    BIN
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.BIN || BIN}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column width={5}>
                  <Header className='secundary-title' as='h3' style={{ textAlign: 'right' }}>
                    PCN
                  </Header>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header className='secundary-content' as='h2' style={{ textAlign: 'right' }}>
                    {savingsCard?.PCN || PCN}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className='noMarginPaddingBottom text-center'>
          <Grid.Column>
            <Header as='h1'>DISCOUNT ONLY – NOT INSURANCE</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='noMarginPadding bottomUrl'>
          <Grid.Column as='h4'>
            {/* <Header as='h3'> */}
            <strong>{`https://${subdomain}.amedicarx.com/`}</strong>
            {/* </Header> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </TemplateContainer>
  );
};
export default DiscountCardFrontTemplate;
